<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>岗位：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.JobId"
          clearable
          filterable
          @clear="initialize"
        >
          <el-option
            v-for="item in jobList"
            :key="item.Name"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <span>状态：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.Status"
          clearable
          @clear="initialize"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <div v-if="item.prop === 'StudentName'" class="u-flex">
              <el-image
                style="width: 32px; height: 32px"
                :src="
                  scope.row['PhotoFullPath'] ||
                    'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
                "
                class="u-radius-32 mr-16"
              >
              </el-image>
              {{ scope.row[item.prop] }}
            </div>
            <el-tag
              v-else-if="item.prop === 'Status'"
              class="u-radius-16"
              :type="scope.row[item.prop] | statusFilter"
            >
              {{ scope.row["Status_V"] }}</el-tag
            >
            <span v-else-if="item.prop === 'Age'">{{
              formartDate(new Date(), "year") -
                formartDate(scope.row["Birthday"], "year")
            }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="300px">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-view"
            @click="handleView(scoped.row.Id)"
            >查看</el-button
          >
          <el-button
            type="text"
            icon="el-icon-view"
            class="btn-color"
            @click="handleShowResume(scoped.row.ResumeId)"
            v-if="scoped.row.ResumeId"
            >查看简历</el-button
          >
          <el-button
            type="text"
            icon="el-icon-s-check"
            @click="handleApprove(scoped.row.Id)"
            v-if="scoped.row.Status !== 4 && scoped.row.Status !== 0"
            >审批</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog class="resume" :visible.sync="dialogVisible" width="800px">
      <template slot="title">
        <div class="flex p-28">
          <el-avatar :src="Resume.Photo" :size="72"> </el-avatar>
          <div class="ml-24 u-flex-1 flex-column mr-24">
            <div>
              <span class="fs-16">{{ Resume.DisplayName }}</span>
              <span
                :class="[
                  'resume-avatar-gender',
                  Resume.Gender == 2
                    ? 'el-icon-female resume-avatar-gender-female'
                    : 'el-icon-male resume-avatar-gender-male'
                ]"
              ></span>
            </div>
            <div class="mt-12 fs-12">
              <span>{{ formartDate(Resume.BirthDay, "month") }}</span>
            </div>
            <el-row class="mt-8 fs-12">
              <el-col :span="8">
                <span>期望职位：{{ Resume.ExpectJobName }}</span>
              </el-col>
              <el-col :span="8">
                <span
                  >期望薪资：{{ Resume.SalaryMin }}-{{
                    Resume.SalaryMax
                  }}K</span
                >
              </el-col>
              <el-col :span="8">
                <span>期望地点：{{ Resume.ExpectWorkPlace }}</span>
              </el-col>
            </el-row>
            <el-row class="mt-8 fs-12">
              <el-col :span="8">
                <span>联系电话：{{ Resume.ContactNumber }}</span>
              </el-col>
              <el-col :span="10">
                <span>联系邮箱：{{ Resume.Email }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <div class="dialog-content">
        <span class="content-title">基本信息</span>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">性别：</span
              ><span class="item-txt">{{ Resume.GenderDescription }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">出生年月：</span
              ><span class="item-txt">{{
                formartDate(Resume.BirthDay, "month")
              }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">籍贯：</span
              ><span class="item-txt">{{ Resume.NativePlace }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">婚姻状况：</span
              ><span class="item-txt">{{ Resume.MaritalStatus }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">政治面貌：</span
              ><span class="item-txt">{{ Resume.PoliticalStatus }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">最高学历：</span
              ><span class="item-txt">{{ Resume.Education }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item">
              <span class="item-type">现住地址：</span
              ><span class=" present-address">{{ Resume.PresentAddress }}</span>
            </div>
          </el-col>
        </el-row>
        <span class="content-title">工作经历</span>
        <el-row
          :gutter="20"
          v-for="item in Resume.ResumeCareerDtoList"
          :key="item.Id"
        >
          <el-col :span="24">
            <div class="info-item text-over flex u-row-between">
              <span class="item-txt color-base fs-16">{{ item.JobName }}</span>
              <span class="item-txt color-light fs-12">
                {{ formartDate(item.StartDate, "day") }} 至
                {{ formartDate(item.EndDate, "day") }}
              </span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item text-over">
              <span class="item-type">描述：</span
              ><span class="item-txt">{{ item.Description }}</span>
            </div>
          </el-col>
        </el-row>
        <span class="content-title">自我评价：</span>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="info-item text-over">
              <span class="item-txt">{{ Resume.Introduction }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import contentTools from "@/mixins/content-tools";
import { getPageList, getInternshipJobList } from "@/api/jobApply";
import { getInfo } from "@/api/resume";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  mixins: [contentTools],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      Resume: {},
      queryParams: {
        PageSize: 999,
        PageIndex: 1,
        EnterpriseId: ""
      },
      tableData: [],
      tableConfig: [
        {
          label: "姓名",
          prop: "StudentName"
        },
        {
          label: "性别",
          prop: "Sex_V"
        },
        {
          label: "年龄",
          prop: "Age"
        },
        {
          label: "学校",
          prop: "SchoolName"
        },
        {
          label: "专业",
          prop: "MarjorName"
        },
        {
          label: "申请岗位",
          prop: "ApplyJobNames_V"
        },
        {
          label: "状态",
          prop: "Status"
        }
      ],
      jobList: [],
      statusList: [
        { label: "驳回", value: 0 },
        { label: "未提交", value: 1 },
        { label: "审批中", value: 2 },
        { label: "审批通过", value: 4 }
      ]
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    baseRoute() {
      return this.RoleType === StaffTypeEnum.School ? "school" : "enterprise";
    }
  },
  created() {
    this.initialize();
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "danger",
        1: "info",
        2: "warning",
        4: "success"
      };
      if (statusMap[status]) {
        return statusMap[status];
      } else {
        return "";
      }
    }
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = null;
          temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          this.queryParams.EnterpriseId = null;
        }
        await this.getList();
        const res = await getPageList(this.queryParams);
        if (res.IsSuccess) {
          this.tableData = res.Result.Data;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    handleSearch() {
      this.initialize();
    },
    async getList() {
      const res = await getInternshipJobList(this.queryParams);
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    async handleShowResume(id) {
      this.dialogVisible = true;
      const res = await getInfo(id);
      if (res.IsSuccess) {
        this.Resume = res.Result;
      }
    },
    handleApprove(id) {
      this.$router.push(`/${this.baseRoute}/practice/approve/detail?id=${id}`);
    },
    handleView(id) {
      this.$router.push(
        `/${this.baseRoute}/practice/approve/detail?id=${id}&view=true`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.resume {
  /deep/.el-dialog__header {
    background: linear-gradient(360deg, #14a495 0%, #598DF3 100%);
    color: #fff;
  }
}

.dialog-content {
  min-height: 400px;
  display: flex;
  padding: 10px 28px;
  flex-direction: column;
  // overflow-y: auto;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: "";
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #598DF3;
      margin-right: 5px;
    }
  }
}
.info-item {
  display: flex;
  // justify-content: space-between;
  align-items: baseline;
  padding: 10px 5px;
  .item-type {
    color: #999;
  }
}
</style>
