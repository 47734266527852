import request from '@/utils/request'
const controller = '/Resume'

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: 'get',
    params: { id }
  });

export {
  getPageList,
  getInfo
}